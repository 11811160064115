import ContactPage from "components/contact/ContactPage";
import { blockPopupAtom } from "components/page/atoms/globalAtoms";
import GlobalLayout from "components/page/GlobalLayout";
import { useSetAtom } from "jotai";
import React, { useEffect } from "react";
import Helmet from "react-helmet";

export default function CustomQuoteLandingPage() {
  const setBlockPopup = useSetAtom(blockPopupAtom);
  useEffect(() => {
    setBlockPopup(true);
    return () => {
      setBlockPopup(false);
    };
  }, []);
  return (
    <GlobalLayout color="var(--gradient-light)" landingPage>
      <Helmet>
        <title>Get Rhombus Price Quote - Commercial Video Surveillance </title>
        <meta
          name="description"
          content="Submit form for quote. Discounts available for volume orders, schools, & non-profits. For any questions, contact us at quote@rhombus.com or 916-907-7404."
        />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <ContactPage telephone="9169077404" email="quote@rhombus.com" />
    </GlobalLayout>
  );
}
